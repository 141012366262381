import { put, takeLatest } from 'redux-saga/effects';
import { createAppTableSaga, createAppTableStore, createPersist } from 'components/AppTable/store';
import apiRoutes from 'config/apiRoute';
import { convertMinsToHrsMins } from 'utils/string.utils';

export const requestSlice = createAppTableStore({
  name: 'tableRequests' as const,
  url: apiRoutes.REQUESTS,
  initialState: {
    params: {
      filters: {
        status: [
          { value: 'new' },
          { value: 'waiting_approval' },
          { value: 'approved' },
          { value: 'needs_attention' },
          { value: 'in_progress' },
        ],
        'user.uuid': [],
      },
      sort: { column: 'created_at', order: 'descend' },
    },
    paramFilterFields: {
      status: 'filters[projects_requests.status][]',
    },
    searchFilters: {
      created_at_start: { rule: 'greater_than_or_equals', field: 'projects_requests.created_at', index: 1 },
      created_at_end: { rule: 'less_than_or_equals', field: 'projects_requests.created_at', index: 2 },
      target_date_start: { rule: 'greater_than_or_equals', field: 'projects_requests.target_date', index: 3 },
      target_date_end: { rule: 'less_than_or_equals', field: 'projects_requests.target_date', index: 4 },
    },
    searchField: '*',
    searchKey: 'search_term[0][0]',
    fixedParams: { with_total_time: true },
    totalTime: null as null | string,
  },
  reducers: {
    setTotalTime: (state, { payload }: { payload: string; type: string }) => {
      return {
        ...state,
        totalTime: payload,
      };
    },
  },
});

function* handleApiResponseSagaListener({ payload }: { payload: any }) {
  yield put(requestSlice.actions.setTotalTime(convertMinsToHrsMins(Number(payload.total_time))));
}

export const requestsSaga = createAppTableSaga(requestSlice, function* customGenerator() {
  yield takeLatest(requestSlice.actions.handleApiResponse, handleApiResponseSagaListener);
});

export const requestsReducer = createPersist(requestSlice);
