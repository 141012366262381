import { FC } from 'react';
import { Col, Pagination, Row, Typography } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { AppTableInitialState, AppTableSlice } from './store';
import styles from './appTable.module.less';

const { Text } = Typography;

interface AppTablePaginationProps {
  storeSlice: AppTableSlice;
  size?: SizeType;
  textCount?: string;
  prefixPagination?: string;
  hidePagination?: boolean;
}

const AppTablePagination: FC<AppTablePaginationProps> = ({
  storeSlice,
  size = 'small',
  textCount,
  prefixPagination,
  hidePagination = false,
}) => {
  const pagination = useSelector((globalStore: RootState) => (globalStore[storeSlice.name] as AppTableInitialState).pagination);

  const paginationTotal = useSelector((globalStore: RootState) => {
    const store = globalStore[storeSlice.name] as AppTableInitialState;

    return store.pagination?.total ?? store.data.length;
  });

  const dispatch = useDispatch();

  return (
    <Row justify="space-between" className={styles.footer} gutter={[4, 10]}>
      <Col>
        {textCount ? (
          <Text className="primary">
            {prefixPagination} {paginationTotal} {textCount}
          </Text>
        ) : undefined}
      </Col>

      <Col>
        {!hidePagination && pagination ? (
          <Pagination
            pageSize={pagination.pageSize}
            current={pagination.current}
            size={size === 'small' ? size : 'default'}
            total={pagination.total}
            showSizeChanger
            onChange={(pageCurrent, pageSize) => {
              if (pagination) {
                dispatch(
                  storeSlice.actions.setPagination({
                    current: pageSize !== pagination.pageSize ? 1 : pageCurrent,
                    pageSize: pageSize ?? pagination.pageSize,
                  }),
                );
              }
            }}
          />
        ) : undefined}
      </Col>
    </Row>
  );
};

export default AppTablePagination;
