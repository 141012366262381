/* eslint-disable no-nested-ternary */
import { FC, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import isMatch from 'lodash/isMatch';
import {
  Badge,
  Button,
  Col,
  Collapse,
  Form,
  InputNumber,
  Drawer,
  Modal,
  Row,
  Select,
  Skeleton,
  Switch,
  Tabs,
  Typography,
  Spin,
  Tooltip,
} from 'antd';
import { ExclamationCircleFilled, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { TbCalendarEvent, TbClock, TbClockPlus, TbStatusChange } from 'react-icons/tb';
import { MdKeyboardDoubleArrowDown, MdKeyboardDoubleArrowUp } from 'react-icons/md';
import { taskTimelogReportSlice } from 'modules/tasks/store/taskTimelogReport';
import getTaskStatusOptions from 'modules/project/config/getTaskStatusOptions';
import RichTextEditor from 'components/RichTextEditor';
import UserAvatars from 'components/UserAvatars';
import UploadDragger from 'components/FileUpload/UploadDragger';
import StatusTag from 'components/StatusTag';
import Comments from 'components/Comments';
import TimerDropdown from 'components/Timer/TimerDropdown';
import ErrorResult from 'components/ErrorResult';
import EditableParagraphInput, { EditableParagraphInputHandle } from 'components/EditableParagraphInput';
import FieldTimeRangeCustom from 'components/FieldTimeRangeCustom';
import ActivityLogs from 'components/ActivityLogs';
import { convertSnakeCaseToTitleCase, getNameAbbrev } from 'utils/string.utils';
import apiRequests from 'utils/api';
import apiRoutes from 'config/apiRoute';
import type { UploadFileApi } from 'types/upload-type';
import ProjectTag from './components/ProjectTag';
import ParentTask from './components/ParentTask';
import PriorityDropdown from './components/PriorityDropdown';
import SubTasksCollapse from './components/SubTasksCollapse';
import TaskTagSelect from './components/TaskTagSelect';
import SelectFollowers from './components/SelectFollowers';
import RequestCard from './components/RequestCard';
import SelectResponsible from './components/SelectResponsible';
import StartDueDatePicker from './components/StartDueDatePicker';
import useTaskModal from './useTaskModal';
import './index.styles.less';
import TaskTimelogTable from './components/TaskTimelogTable';

const { Panel } = Collapse;
const { Text } = Typography;

const descriptionPlaceholder = 'Describe in detail what your requirements and needs are.';

interface TaskModalBodyHandle {
  safeClose: () => void;
}

interface TaskModalBodyProps {
  uuid: any;
  highlightCommentUuid?: string;
  createToParentUuid?: string;
  createToStatus?: string;
  createFromTaskUuid?: string;
  project?: any;
  initialFiles?: UploadFileApi[];
  isPersonal?: boolean;
  onClose?: () => void;
  onCommentsLoaded?: any;
  onAddTimeLogEntry?: any;
  onRemoveRequest?: (taskId: string) => void;
  onUpdateSubtask?: () => void;
  onImageModalChanged?: (open: boolean) => void;
  onCustomizeDataApi?: (values: Record<string, any>) => Record<string, any>;
  onSaved?: (data: Record<string, any>) => void;
}

const TaskModalBody = forwardRef<TaskModalBodyHandle, TaskModalBodyProps>(
  (
    {
      uuid,
      highlightCommentUuid,
      createToParentUuid,
      createToStatus,
      createFromTaskUuid,
      project,
      initialFiles,
      isPersonal,
      onClose,
      onCommentsLoaded,
      onAddTimeLogEntry,
      onRemoveRequest,
      onUpdateSubtask,
      onImageModalChanged,
      onSaved,
      onCustomizeDataApi,
    },
    ref,
  ) => {
    const {
      state,
      initialValues,
      form,
      isCreateModal,
      filesData,
      fetchTaskError,
      scrollRef,
      setState,
      createToParentTask,
      selectedProject,
      updateDirtyFields,
      onCreateHandler,
      onUpdateHandler,
      onUpdateParentTask,
      onButtonUpdateHandler,
      onFieldsChangeHandler,
      setProject,
      setFilesData,
      fetchTask,
      scrollToBottom,
      fetching,
      fetchingParent,
      saving,
      connectedUsers,
      setConnectedUsers,
      editorStatus,
      setEditorStatus,
      descriptionTextRef,
    } = useTaskModal({
      uuid,
      project,
      initialFiles,
      isPersonal,
      createToParentUuid,
      createToStatus,
      createFromTaskUuid,
      onSaved,
      onCustomizeDataApi,
    });

    const [hasFileLoading, setHasFileLoading] = useState(false);
    const [loadingImages, setLoadingImages] = useState(0);
    const [openUploadingFiles, setOpenUploadingFiles] = useState(false);
    const [openUnsavedAlert, setOpenUnsavedAlert] = useState(false);
    const [collapsedFilters, setCollapsedFilters] = useState(isCreateModal);

    const titleRef = useRef<EditableParagraphInputHandle>(null);

    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
      safeClose() {
        if (!isCreateModal && (hasFileLoading || loadingImages)) {
          setOpenUploadingFiles(true);
          return;
        }

        if (state?.recurrence && !isCreateModal && !isMatch(initialValues, form.getFieldsValue())) {
          setOpenUnsavedAlert(true);
          return;
        }

        if (!isCreateModal) {
          updateDirtyFields();
        }

        onClose?.();
      },
    }));

    useEffect(() => {
      if (openUploadingFiles && !hasFileLoading && !loadingImages) {
        setOpenUploadingFiles(false);
        onClose?.();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openUploadingFiles, hasFileLoading, loadingImages]);

    useEffect(() => {
      if (isCreateModal && project) {
        const timeout = setTimeout(() => titleRef.current?.focus(), 0);

        return () => {
          clearTimeout(timeout);
        };
      }

      return () => {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const taskBody = (
      <>
        <Modal
          visible={openUploadingFiles}
          footer={null}
          maskClosable={false}
          onCancel={() => setOpenUploadingFiles(false)}
          destroyOnClose
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12 }}>
            <div className="primary">
              <InfoCircleOutlined style={{ fontSize: 32 }} />
            </div>
            <div>
              <span style={{ paddingRight: 12 }}>Finishing to upload files, please wait... </span>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 22 }} spin />} />
            </div>
          </div>
        </Modal>

        <Modal
          visible={openUnsavedAlert}
          className="ant-modal-confirm"
          okText="Yes, save changes"
          cancelText="Close it anyway"
          cancelButtonProps={{ onClick: () => onClose?.() }}
          onOk={() => {
            setOpenUnsavedAlert(false);
            onButtonUpdateHandler();
          }}
          onCancel={() => {
            setOpenUnsavedAlert(false);
          }}
          destroyOnClose
        >
          <div className="ant-modal-confirm-body">
            <ExclamationCircleFilled style={{ fontSize: 20 }} />

            <span className="ant-modal-confirm-title">Unsaved changes</span>

            <div className="ant-modal-confirm-content">You have unsaved changes in this task, do you want to save?</div>
          </div>
        </Modal>

        <div className="task-card">
          <div className="task-card--header">
            {state?.parent ? <ParentTask task={state} onChange={onUpdateParentTask} /> : undefined}

            {createToParentTask ? <Text className="primary">{createToParentTask.title}</Text> : undefined}

            <Form
              form={form}
              size="small"
              initialValues={initialValues}
              onFieldsChange={onFieldsChangeHandler}
              validateTrigger={['onSubmit']}
            >
              <Row style={{ paddingRight: 30 }}>
                <Col className="task-card--header-col-title">
                  <span style={{ paddingTop: 4 }}>
                    <Form.Item name="priority" noStyle>
                      <PriorityDropdown />
                    </Form.Item>
                  </span>

                  <Form.Item
                    name="title"
                    className="mb-0 w-full"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <EditableParagraphInput
                      ref={titleRef}
                      maxLength={72}
                      onChangeDebounce={() => onFieldsChangeHandler([], [])}
                    />
                  </Form.Item>
                </Col>

                {connectedUsers.size ? (
                  <Col xs={24} className="task-card--header-col-users sm-flex-none">
                    <UserAvatars users={Array.from(connectedUsers.values())} maxCount={2} />
                  </Col>
                ) : undefined}
              </Row>

              <div style={{ display: 'flex', flexWrap: 'nowrap', gap: 10 }}>
                <Form.Item
                  name="project_id"
                  style={{ margin: '0 0 0 28px', flex: '1 1 auto', overflow: 'hidden' }}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <ProjectTag
                    project={selectedProject}
                    canEdit={!state?.parent && !createToParentTask && !isPersonal && (!isCreateModal || !project)}
                    onChange={(value) => {
                      form.setFieldsValue({ project_id: value?.uuid ?? null });
                      setProject(value);
                      if (isCreateModal) {
                        titleRef.current?.focus();
                      }
                    }}
                  />
                </Form.Item>

                {state?.creator ? (
                  <Tooltip title={`At ${moment(state.created_at).format('MM/DD/YY')}`} placement="left">
                    <span className="secondary" style={{ flex: 'none' }}>
                      By: {getNameAbbrev(state.creator.name)}
                    </span>
                  </Tooltip>
                ) : undefined}
              </div>
            </Form>

            {isCreateModal || state?.recurrence ? (
              <Button
                size="large"
                type="primary"
                disabled={hasFileLoading || loadingImages > 0}
                loading={saving}
                style={{ marginTop: 12 }}
                onClick={isCreateModal ? onCreateHandler : onButtonUpdateHandler}
                block
              >
                Submit
              </Button>
            ) : undefined}

            <Row gutter={8} wrap={false}>
              {state?.recurrence_type && state?.recurrence_type !== 'none' && (
                <Col>
                  <StatusTag value={state?.recurrence_type} />
                </Col>
              )}
            </Row>
          </div>

          <div className="task-card--body app-scrollbar" ref={scrollRef}>
            <div style={{ flex: '1 1 auto' }}>
              <Form
                className={`form-box-outline${selectedProject ? '' : ' read-only'}`}
                style={{
                  opacity: selectedProject ? undefined : 0.4,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  overflowX: 'hidden',
                  paddingBottom: 12,
                }}
                colon={false}
                form={form}
                size="middle"
                layout="vertical"
                initialValues={initialValues}
                onFieldsChange={onFieldsChangeHandler}
              >
                <Row gutter={[8, 8]} style={{ marginTop: 10, marginBottom: 8 }}>
                  <Col xs={24} sm={12} className="md-flex-auto">
                    <Form.Item
                      label={
                        <>
                          <TbStatusChange />
                          Status
                        </>
                      }
                      className="mb-0"
                      name="status"
                    >
                      <Select
                        onChange={(value: any) => {
                          setState((prevState: any) => ({ ...prevState, status: value }));
                        }}
                        options={
                          state?.recurrence
                            ? [
                                {
                                  label: 'Master',
                                  value: 'seed',
                                },
                              ]
                            : getTaskStatusOptions({
                                status: state?.status,
                                responsible: state?.responsibles,
                              })
                        }
                        placeholder="Status"
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    flex={isPersonal ? 'auto' : undefined}
                    xs={isPersonal ? undefined : 24}
                    sm={isPersonal ? undefined : 12}
                    className={isPersonal ? undefined : 'md-flex-none'}
                  >
                    <Form.Item
                      label={
                        <>
                          <TbCalendarEvent />
                          Start date / Due date
                        </>
                      }
                      name="start_due_date"
                      style={{ marginBottom: '0' }}
                      rules={[
                        ...(state?.recurrence
                          ? [
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]
                          : []),
                      ]}
                    >
                      <StartDueDatePicker />
                    </Form.Item>
                  </Col>

                  {!isCreateModal && !state?.recurrence && (
                    <Col
                      flex={isPersonal ? 'auto' : undefined}
                      xs={isPersonal ? undefined : 24}
                      className={isPersonal ? undefined : 'sm-flex-none'}
                      style={{ width: 140 }}
                    >
                      <Form.Item
                        label={
                          <>
                            <TbClockPlus />
                            Time tracking
                          </>
                        }
                        style={{ marginBottom: '0', width: '100%' }}
                      >
                        <Button type="ghost" style={{ width: '100%' }}>
                          <TimerDropdown
                            onAddEntry={() => {
                              dispatch(taskTimelogReportSlice.actions.fetch());
                              if (state?.status === 'todo') {
                                form.setFieldsValue({ status: 'in_progress' });
                                setState((prev: any) => ({ ...prev, status: 'in_progress' }));
                                onFieldsChangeHandler([], []);
                                onAddTimeLogEntry?.();
                              }
                            }}
                            needsAlert
                            taskStatus={state?.status}
                            projectId={selectedProject?.uuid}
                            key={state?.uuid}
                            task_id={state?.uuid}
                            showUserSelection
                          />
                        </Button>
                      </Form.Item>
                    </Col>
                  )}

                  {!isPersonal && (
                    <Col className="col-responsible">
                      <SelectResponsible
                        form={form}
                        state={state}
                        finalProjectId={selectedProject?.uuid}
                        setState={setState}
                        dropdownMatchSelectWidth={190}
                        onlyAvatar
                      />
                    </Col>
                  )}

                  <Col flex="none">
                    <Button
                      size="small"
                      type="default"
                      style={{ height: 52, padding: '0 4px' }}
                      onClick={() => setCollapsedFilters(!collapsedFilters)}
                    >
                      {collapsedFilters ? (
                        <MdKeyboardDoubleArrowUp style={{ fontSize: 20, verticalAlign: 'middle' }} />
                      ) : (
                        <MdKeyboardDoubleArrowDown style={{ fontSize: 20, verticalAlign: 'middle' }} />
                      )}
                    </Button>
                  </Col>
                </Row>

                <Collapse bordered={false} activeKey={collapsedFilters ? '1' : ''} className="headless">
                  <Collapse.Panel header="" key="1" forceRender>
                    <Row gutter={[8, 8]} style={{ marginBottom: 10 }}>
                      <Col xs={24} sm={isPersonal ? 24 : 12}>
                        <FieldTimeRangeCustom
                          name="estimated_time_range"
                          className="mb-0 w-full"
                          label={
                            <>
                              <TbClock />
                              Estimated Time
                            </>
                          }
                        />
                      </Col>

                      {!isPersonal && (
                        <Col xs={24} sm={12}>
                          <SelectFollowers form={form} state={state} finalProjectId={selectedProject?.uuid} setState={setState} />
                        </Col>
                      )}

                      <Col span={24}>
                        <TaskTagSelect />
                      </Col>

                      {(isCreateModal || state?.recurrence) && (
                        <Col xs={24} sm={4}>
                          <Form.Item className="mb-0" name="recurrence" valuePropName="checked" label="Recurrence">
                            <Switch
                              disabled={!isCreateModal}
                              onChange={(checked) => {
                                const statusVal = checked ? 'seed' : 'todo';

                                setState((prevState: any) => ({ ...prevState, recurrence: checked, status: statusVal }));

                                form.setFieldsValue({ recurrence_type: null, status: statusVal });
                              }}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                            />
                          </Form.Item>
                        </Col>
                      )}
                      {state?.recurrence && (
                        <>
                          <Col xs={12} sm={10}>
                            <Form.Item
                              label="Recurrence type"
                              name="recurrence_type"
                              className="mb-0"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                              <Select
                                options={['weekly', 'bi_weekly', 'monthly', 'every_3_months', 'every_6_months', 'yearly'].map(
                                  (item) => ({
                                    label: convertSnakeCaseToTitleCase(item),
                                    value: item,
                                  }),
                                )}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={12} sm={10}>
                            <Form.Item
                              label="Recurrence limit"
                              name="recurrence_limit"
                              className="mb-0"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                              <InputNumber max={100} style={{ width: '100%' }} min={0} />
                            </Form.Item>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Collapse.Panel>
                </Collapse>

                <RichTextEditor
                  ref={descriptionTextRef}
                  scrollingContainer={scrollRef.current ?? undefined}
                  wrapperClassName="flex-auto"
                  placeholder={descriptionPlaceholder}
                  initialValue={state?.description_delta}
                  showMoreButton={!isCreateModal}
                  progressBar="bottom"
                  imageAsData64
                  containerFullHeight
                  onImageModalChanged={onImageModalChanged}
                  onLoadingImage={setLoadingImages}
                  onImageUploader={async (upload) => {
                    setFilesData((prev) => [...prev, upload]);

                    if (!isCreateModal) {
                      await onUpdateHandler({ uploads: [upload.response?.uuid] });
                    }

                    return undefined;
                  }}
                  collaborativeEditor={
                    !isCreateModal
                      ? {
                          room: `task-editor-${uuid}`,
                          users: connectedUsers,
                          status: editorStatus,
                          onUserUpdated: setConnectedUsers,
                          onStatusChanged: setEditorStatus,
                          onYDocUpdated: async (description) => {
                            await apiRequests.put(`${apiRoutes.PROJECT_TASKS}/${uuid}/description-delta`, {
                              description_delta: description,
                            });
                          },
                        }
                      : { useYDoc: true }
                  }
                />
              </Form>
            </div>

            <div>
              <Collapse className="task-modal-collapse no-padding mb-m">
                <Panel
                  header="Assets"
                  key="asset"
                  extra={<Badge count={filesData.length} className={!filesData.length ? 'ant-badge-gray ' : ''} showZero />}
                >
                  <UploadDragger
                    uploads={filesData}
                    setLoading={setHasFileLoading}
                    setUploads={setFilesData}
                    showDownloadIcon
                    showRemoveIcon
                    resource={!isCreateModal ? 'task' : undefined}
                    resourceIds={[uuid]}
                    onRequest={
                      isCreateModal
                        ? undefined
                        : async ({ uuid: uploadUuid }) => {
                            return !!(await onUpdateHandler({ uploads: [uploadUuid] }));
                          }
                    }
                  />
                </Panel>
              </Collapse>

              {!isCreateModal && !state?.recurrence && (
                <SubTasksCollapse
                  task={state}
                  onTaskUpdate={async () => {
                    onUpdateSubtask?.();
                    await fetchTask();
                  }}
                />
              )}

              {!isCreateModal && (
                <RequestCard
                  taskId={uuid}
                  projectUuid={selectedProject?.uuid}
                  requests={state?.requests ?? []}
                  onRelate={(newRequests) => {
                    setState((oldState: any) => ({
                      ...oldState,
                      requests: newRequests,
                    }));
                  }}
                  onRemove={(request) => {
                    setState((oldState: any) => ({
                      ...oldState,
                      requests: oldState.requests.filter((x: any) => x.uuid !== request.uuid),
                    }));
                    onRemoveRequest?.(state.uuid);
                  }}
                />
              )}

              {!isCreateModal && (
                <Tabs>
                  <Tabs.TabPane tab="Comments" key="item-1">
                    <Comments
                      key={state?.uuid}
                      type="tasks"
                      typeUuid={state?.uuid}
                      highlightUuid={highlightCommentUuid}
                      projectUuid={selectedProject?.uuid}
                      isProjectPersonal={selectedProject?.kind === 'personal'}
                      maxHeight="370px"
                      scrollingContainer=".task-card--body"
                      mentionTypes={['regular_user', 'freelancer', 'group']}
                      hideTemplates
                      onCommentsLoaded={() => {
                        const timer = setTimeout(() => {
                          scrollToBottom();
                          if (onCommentsLoaded) {
                            onCommentsLoaded();
                          }
                        }, 100);

                        return () => {
                          clearTimeout(timer);
                        };
                      }}
                      onFinishSubmit={(_, images) => {
                        setFilesData((old) => [...old, ...images]);
                      }}
                      onFilesDelete={(listUuid) => {
                        setFilesData((old) => old.filter((item) => !listUuid.includes(item.response?.uuid ?? item.uid)));
                      }}
                      onImageModalChanged={onImageModalChanged}
                    />
                  </Tabs.TabPane>

                  <Tabs.TabPane tab="Task activity" key="item-2">
                    <ActivityLogs model={state} modelType="task" />
                  </Tabs.TabPane>

                  <Tabs.TabPane tab="Timelogs" key="item-3">
                    <TaskTimelogTable uuid={uuid} />
                  </Tabs.TabPane>
                </Tabs>
              )}
            </div>
          </div>
        </div>
      </>
    );

    if (fetchTaskError) {
      return <ErrorResult error={fetchTaskError} />;
    }

    return fetching || fetchingParent ? <Skeleton active /> : taskBody;
  },
);

TaskModalBody.displayName = 'TaskModalBody';

interface TaskModalProps {
  visible: any;
  highlightCommentUuid?: string;
  createToParentUuid?: string;
  createToStatus?: string;
  createFromTaskUuid?: string;
  project?: any;
  initialFiles?: UploadFileApi[];
  mask?: boolean;
  isInboxPage?: boolean;
  isPersonal?: boolean;
  onClose?: () => void;
  onCommentsLoaded?: any;
  onAddTimeLogEntry?: any;
  onRemoveRequest?: (taskId: string) => void;
  onUpdateSubtask?: () => void;
  onCustomizeDataApi?: (values: Record<string, any>) => Record<string, any>;
  onSaved?: (data: Record<string, any>) => void;
}

const TaskModal: FC<TaskModalProps> = ({
  visible,
  highlightCommentUuid,
  createToParentUuid,
  createToStatus,
  createFromTaskUuid,
  project,
  initialFiles,
  mask,
  isInboxPage,
  isPersonal,
  onClose,
  onCommentsLoaded,
  onAddTimeLogEntry,
  onRemoveRequest,
  onUpdateSubtask,
  onSaved,
  onCustomizeDataApi,
}) => {
  const taskBodyRef = useRef<TaskModalBodyHandle>(null);
  const uuidRef = useRef(visible);
  const [enableDrawerKeyboard, setEnableDrawerKeyboard] = useState(true);

  if (uuidRef.current !== visible && visible) {
    uuidRef.current = visible;
  }

  const body = (
    <div style={{ padding: '16px 16px 0 16px', height: '100%' }}>
      <TaskModalBody
        key={uuidRef.current}
        uuid={uuidRef.current}
        ref={taskBodyRef}
        highlightCommentUuid={highlightCommentUuid}
        createToParentUuid={createToParentUuid}
        createToStatus={createToStatus}
        createFromTaskUuid={createFromTaskUuid}
        project={project}
        initialFiles={initialFiles}
        isPersonal={isPersonal}
        onClose={onClose}
        onCommentsLoaded={onCommentsLoaded}
        onAddTimeLogEntry={onAddTimeLogEntry}
        onRemoveRequest={onRemoveRequest}
        onUpdateSubtask={onUpdateSubtask}
        onImageModalChanged={!isInboxPage ? (open) => setEnableDrawerKeyboard(!open) : undefined}
        onSaved={onSaved}
        onCustomizeDataApi={onCustomizeDataApi}
      />
    </div>
  );

  if (isInboxPage) {
    return body;
  }

  return (
    <Drawer
      visible={!!visible}
      mask={mask}
      width={640}
      footer={null}
      keyboard={enableDrawerKeyboard}
      className="task-drawer"
      bodyStyle={{
        padding: 0,
      }}
      destroyOnClose
      maskClosable
      onClose={() => {
        taskBodyRef.current?.safeClose();
      }}
    >
      {body}
    </Drawer>
  );
};

export default TaskModal;
