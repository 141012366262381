import { Dispatch, FC, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Divider, Empty, Row, Space, Spin, Tabs, Typography } from 'antd';
import TimeReportProgress from 'modules/project/components/TimeReportProgress';
import TaskModal from 'components/TaskModal';
import Comments from 'components/Comments';
import ActivityLogs from 'components/ActivityLogs';
import { FormBuilderData } from 'types/formBuilder';
import { convertMinsToHrsMins, getQueryValue, localRandomUuid, renderTime } from 'utils/string.utils';
import { mapModelToUploadFileApi } from 'utils/array.utils';
import useWebSocket from 'utils/useWebSocket';
import { RootState } from 'store';
import RequestTaskCard from '../RequestTaskCard';
import RelateTaskRequestModal from '../RelateTaskRequestModal';
import RequestForm, { RequestFormProps } from '../RequestForm';
import RequestTimelogTab from '../RequestTimelogTab';
import style from '../RequestForm/index.module.less';
import styleNoDraft from './requestUpdateNoDraft.module.less';
import { UserResponse } from '../../../../types';

const { Title } = Typography;

const getAllRelatedTasksUuid = (tasks: any[]): string[] => {
  return tasks.reduce((acc, task) => [...acc, task.uuid, ...getAllRelatedTasksUuid(task.subtasks)], []);
};

interface RequestUpdateNoDraftProps {
  request: Record<string, any>;
  formBuilder?: FormBuilderData;
  projectUuid?: string;
  pageUrl: string;
  wrapSize: 'xl' | 'xxl';
  highlightCommentUuid?: string;
  resetForm?: RequestFormProps['resetForm'];
  goBack?: boolean;
  noPadding?: boolean;
  showTimeReport?: boolean;
  setRequest: Dispatch<any>;
  onSaveRequest: (values: any) => Promise<Record<string, any>>;
  onFetchRequest: () => Promise<void>;
}

const RequestUpdateNoDraft: FC<RequestUpdateNoDraftProps> = ({
  request,
  formBuilder,
  projectUuid,
  pageUrl,
  wrapSize,
  highlightCommentUuid,
  resetForm,
  goBack,
  noPadding,
  showTimeReport,
  setRequest,
  onSaveRequest,
  onFetchRequest,
}) => {
  const user: UserResponse = useSelector((store: any) => store.auth.user);
  const globalTask = useSelector((store: RootState) => store.globalTaskModal);
  const globalLogTrigger = useSelector((globalState: RootState) => globalState.globalLog.trigger);

  const [tasksLoading, setTasksLoading] = useState(false);
  const [openRelateTaskModal, setOpenRelateTaskModal] = useState(false);

  const history = useHistory();

  const dispatch = useDispatch();

  const taskUuid = getQueryValue(history.location.search, 'uuid');

  const files = useMemo(() => mapModelToUploadFileApi(request.uploads), [request.uploads]);

  const totalTime = request.tasks_total_time;

  const isFreelancer = user.contact.type === 'freelancer';

  const fetchRequestTask = () => {
    setTasksLoading(true);

    onFetchRequest().finally(() => setTasksLoading(false));
  };

  const removeTask = (removeTaskUuid: string) => {
    fetchRequestTask();

    setRequest({
      ...request,
      tasks: request.tasks.filter((task: any) => task.uuid !== removeTaskUuid),
    });
  };

  const onValidateStatusComplete = (formValues: Record<string, any>) => {
    if (!request) return false;

    let tasksCompleted = true;
    let tasksAssignees = true;
    let tasksTimelogs = true;

    const validateTasks = (tasks: any[]) => {
      tasks.find((task) => {
        if (!['completed', 'cancelled'].includes(task.status)) {
          tasksCompleted = false;
        }

        if (!task.related_users.find((relatedUser: any) => relatedUser.pivot.type === 'responsible')) {
          tasksAssignees = false;
        }

        if (task.total_timelogs === 0) {
          tasksTimelogs = false;
        }

        return !tasksCompleted && !tasksAssignees && !tasksTimelogs;
      });
    };

    validateTasks(request.tasks);

    const list = [
      { label: 'Time spent filled in', validate: Number(request.time_spent) > 0 },
      { label: 'Time spent less than maximum estimated time', validate: Number(request.time_spent) <= formValues.estimated_max },
      { label: 'All related tasks completed', validate: tasksCompleted },
      { label: 'Responsible person filled in', validate: formValues.responsibles.length > 0 },
      { label: 'All tasks have assignees', validate: tasksAssignees },
      { label: 'All tasks have timelogs', validate: tasksTimelogs },
    ];

    return list.find((item) => !item.validate) ? list : true;
  };

  const onAddedTimelog = (timelog: any) => {
    setRequest({ ...request, time_spent: (Number(request.time_spent) + timelog.minutes).toString() });
  };

  useEffect(() => {
    fetchRequestTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalLogTrigger, globalTask.savedTrigger, globalTask.removedRequestTrigger, globalTask.updatedSubtaskTrigger, dispatch]);

  useWebSocket({
    channelName: 'model.changes',
    listen: {
      event: '.app.models.projects.timelog',
      callback: (event: any) => {
        if (event.payload.ref_token === localRandomUuid) return;

        if (
          (showTimeReport && getAllRelatedTasksUuid(request.tasks).includes(event.payload.task_id)) ||
          (!showTimeReport && request.project.uuid === event.payload.project_id)
        ) {
          fetchRequestTask();
        }
      },
    },
  });

  useWebSocket({
    channelName: 'model.changes',
    listen: {
      event: '.app.models.projects.requesttimelog',
      callback: (event: any) => {
        if (event.payload.ref_token === localRandomUuid || event.payload.request_id !== request.uuid) return;

        if (event.action === 'create') {
          setRequest({
            ...request,
            time_spent: event.payload.request_time_spent,
          });

          return;
        }

        onFetchRequest();
      },
    },
  });

  useWebSocket({
    channelName: `project.${request.project.uuid}`,
    listen: {
      event: '.project.budget',
      callback: (event: any) => {
        setRequest({
          ...request,
          project: {
            ...request.project,
            time_report: event.time_report,
          },
        });
      },
    },
  });

  const listTasks = useMemo(() => {
    const returnSubtasks = (subtasks: any[], isSubLevel = false): { isSubLevel: boolean; task: Record<string, any> }[] => {
      return subtasks.reduce((acc, task) => {
        if (request.tasks.find((item: any) => item.uuid === task.uuid)) {
          acc.push({ isSubLevel, task });
        }

        return [...acc, ...returnSubtasks(task.subtasks, true)];
      }, []);
    };

    const getSubTaskUuids = (subtasks: any[]): string[] => {
      return subtasks.reduce((acc: string[], subtask: any) => [...acc, subtask.uuid, ...getSubTaskUuids(subtask.subtasks)], []);
    };

    const listSubtasksUuid = request.tasks.reduce((acc: string[], task: any) => [...acc, ...getSubTaskUuids(task.subtasks)], []);

    return returnSubtasks(request.tasks.filter((item: any) => !listSubtasksUuid.includes(item.uuid)));
  }, [request.tasks]);

  return (
    <Card
      className={wrapSize === 'xl' ? style.containerXl : style.containerXxl}
      bodyStyle={{ padding: noPadding ? '0 0 8px' : undefined, paddingBottom: !noPadding ? 8 : undefined, paddingRight: 12 }}
      bordered={false}
    >
      {openRelateTaskModal && (
        <RelateTaskRequestModal
          uuid={request.uuid}
          projectUuid={request.project?.uuid}
          onClose={() => setOpenRelateTaskModal(false)}
          onSuccess={() => fetchRequestTask()}
        />
      )}

      <TaskModal
        visible={taskUuid}
        mask={false}
        project={request.project}
        initialFiles={taskUuid === 'create' ? files : undefined}
        onClose={() => history.push(`${pageUrl}/${request.uuid}`)}
        onSaved={() => {
          fetchRequestTask();
        }}
        onCustomizeDataApi={(values) => {
          return { ...values, add_requests: [request.uuid] };
        }}
      />

      <Row className={style.rowContainer} gutter={[0, 22]}>
        <Col className={style.colContainerLeft}>
          <RequestForm
            type="view"
            request={request}
            formBuilder={formBuilder}
            projectUuid={projectUuid}
            pageUrl={pageUrl}
            classNameColFormQuestions={`${style.colFormQuestions} transparent-scrollbar`}
            resetForm={resetForm}
            goBack={goBack}
            onUpdate={onSaveRequest}
            onValidateStatusComplete={onValidateStatusComplete}
            onAddedTimelog={onAddedTimelog}
            disableFormAnswers
          />
        </Col>

        <Col className={style.colDivider}>
          <Divider type="vertical" style={{ height: '100%', margin: '0 20px' }} className="primary" />
        </Col>

        <Col className={style.colContainerRight}>
          <Row wrap={false} className="flex-col h-full">
            <Col flex="auto">
              <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                {showTimeReport && (
                  <div style={{ paddingBottom: 8 }}>
                    <TimeReportProgress report={request.project.time_report} />
                  </div>
                )}

                <div className="primary" style={{ display: 'flex', gap: 8, justifyContent: 'space-between', paddingBottom: 8 }}>
                  <span>Total time</span>
                  <span>
                    <b>{renderTime(convertMinsToHrsMins(totalTime))}</b>
                  </span>
                </div>

                <div
                  style={{
                    display: 'inline-flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <Title level={4} className="primary no-margin ">
                    Tasks
                  </Title>

                  <Space style={{ paddingBottom: 8 }}>
                    <Link to={`${pageUrl}/${request.uuid}/tasks?uuid=create`}>
                      <Button type="primary" size="large">
                        Create task
                      </Button>
                    </Link>

                    <Button type="default" size="large" onClick={() => setOpenRelateTaskModal(true)}>
                      Relate task
                    </Button>
                  </Space>
                </div>

                <div style={{ overflowY: 'auto', marginRight: -4, padding: '6px 4px 8px 0', height: '100%' }}>
                  <Spin spinning={tasksLoading}>
                    {listTasks.map(({ task, isSubLevel }) => (
                      <RequestTaskCard
                        key={task.uuid}
                        requestUuid={request.uuid}
                        task={task}
                        listTasks={listTasks}
                        margin={isSubLevel}
                        onRemoved={() => removeTask(task.uuid)}
                      />
                    ))}

                    {!request.tasks.length && <Empty description="No tasks" />}
                  </Spin>
                </div>

                <Divider style={{ margin: '10px -10px', width: 'calc(100% + 20px)' }} className="primary" />
              </div>
            </Col>

            <Col flex="none" style={{ maxHeight: 'calc(50vh - 90px)', minHeight: 350 }}>
              <div style={{ height: '100%' }}>
                <Tabs className={`tab-fullscreen tab-secondary ${styleNoDraft.infoTabs}`}>
                  <Tabs.TabPane key="comments" tab="Comments" className={styleNoDraft.commentTab}>
                    <Comments
                      projectUuid={request.project?.uuid}
                      type="requests"
                      typeUuid={request.uuid}
                      maxHeight={!isFreelancer ? 'calc(50vh - 240px)' : 'calc(50vh - 170px)'}
                      minHeight={!isFreelancer ? '198px' : '288px'}
                      textEditorClassName="max-h-small"
                      highlightUuid={highlightCommentUuid}
                      replaceUserInTemplate={request.requester}
                      mentionTypes={['regular_user', 'group', 'contact']}
                      canComment={!isFreelancer}
                    />
                  </Tabs.TabPane>

                  <Tabs.TabPane key="logs" tab="Activity logs">
                    <ActivityLogs model={request} modelType="request" style={{ height: '100%', overflow: 'auto' }} />
                  </Tabs.TabPane>

                  <Tabs.TabPane key="timelogs" tab="Timelogs">
                    <RequestTimelogTab uuid={request.uuid} />
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default RequestUpdateNoDraft;
